import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, Card, CardContent, Chip, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Organization from 'model/organization';
import { getClientList, getOrganizationDistributionUnits, getOrganizationList } from 'helper/backend';
import { useTranslation } from 'react-i18next';
import Client from 'model/client';
import { useAuth } from 'context/auth';
import MuiAutocomplete from 'component/common/MuiAutocomplete';
import DateRangeInput from 'component/common/DateRangeInput';
import AccessLog from 'model/accessLog';
import DistributionUnit from 'model/distributionUnit';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setStatus, setFieldValue, setFieldError }: Props) => {

  const { t } = useTranslation();
  const { authUser } = useAuth();

  // list of organizations that populate the select field
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  // list of clients that populate the select field
  const [clients, setClients] = useState<Client[]>([]);
  // list of dus that populate the select field
  const [distributionUnits, setDistributionUnits] = useState<DistributionUnit[]>([]);

  /**
* Fetches from the backend the list of organizations this user is allowed to see
*/
  const fetchDus = useCallback(() => {
    getOrganizationDistributionUnits()
      .then(response => {
        setDistributionUnits(response.distributionUnits);
      })
      .catch(_ex => {
        setFieldError('distributionUnitId', t("unableToLoadDistributionUnits"));
      });
  }, [setFieldError, t]);

  /**
  * Fetches from the backend the list of organizations this user is allowed to see
  */
  const fetchOrganizations = useCallback(() => {
    getOrganizationList()
      .then(response => {
        setOrganizations(response.organizations);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, t]);

  /**
  * Fetches from the backend the list of clients this user is allowed to see
  */
  const fetchClients = useCallback(() => {
    getClientList()
      .then(response => {
        setClients(response.clients);
      })
      .catch(_ex => {
        setFieldError('clientId', t("unableToLoadClients"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    if (authUser.isMasterAdmin()) {
      fetchOrganizations();
    }
    if (authUser.isOrganizationAdmin()) {
      fetchClients();
    }
    fetchDus();
  }, [fetchOrganizations, fetchClients, authUser, fetchDus]);

  /**
  * Converts the list of distributionUnitId to select options
  */
  const getDuOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = distributionUnits.map(distributionUnit => ({ label: distributionUnit.name, value: distributionUnit.id }));
    options.push(...orgs);
    return options;
  }, [distributionUnits, t]);

  /**
  * Converts the list of organizations to select options
  */
  const getOrganizationOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = organizations.map(org => ({ label: org.companyName, value: org.id }));
    options.push(...orgs);
    return options;
  }, [organizations, t]);

  /**
  * Converts the list of clients to select options
  */
  const getClientOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = clients.map(cli => ({ label: cli.companyName, value: cli.id }));
    options.push(...orgs);
    return options;
  }, [clients, t]);

  /**
 * Converts the list of tags to select options
 */
  const getTagsOptions = useCallback(() => {
    const options = [];
    const allTags = AccessLog.TagTypes.map(tag => ({ label: tag.name, value: tag.id }));
    options.push(...allTags);
    return options;
  }, []);

  /**
  * Returns the selected tag as a select option
  */
  const getSelectedTagOption = () => getTagsOptions().filter(option => values.tags.some((item: number) => item === option.value)) || null;

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('distributionUnitId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('distributionUnitId');
              }}
              value={getDuOptions().find(option => option.value === +values.distributionUnitId) || getDuOptions()[0]}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              groupBy={(option: any) => option?.type!}
              options={getDuOptions()}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderInput={(params: any) => <TextField {...params} label={t("distributionUnit")} error={!!errors.distributionUnitId} helperText={errors.distributionUnitId} />}
            />
          </Grid>
          {
            authUser.isMasterAdmin() &&
            <Grid xs={12} md={3}>
              <MuiAutocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('organizationId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('organizationId');
                }}
                value={getOrganizationOptions().find(option => option.value === values.organizationId)}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                disablePortal
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getOrganizationOptions()}
                renderInput={(params: any) => <TextField {...params} label={t("organization")} error={!!errors.organizationId} helperText={errors.organizationId} />}
              />
            </Grid>
          }
          {
            authUser.isOrganizationAdmin() &&
            <Grid xs={12} md={3}>
              <MuiAutocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('clientId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('clientId');
                }}
                value={getClientOptions().find(option => option.value === +values.clientId) || getClientOptions()[0]}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                disablePortal
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getClientOptions()}
                renderInput={(params: any) => <TextField {...params} label={t("client")} error={!!errors.clientId} helperText={errors.clientId} />}
              />
            </Grid>
          }
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('tags', selectedOption.map((el: any) => el.value))
              }}
              onFocus={_e => {
                onSelectFieldFocused('tags');
              }}
              multiple
              value={getSelectedTagOption()}
              options={getTagsOptions()}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={AccessLog.getTagTypeName(option.value)} {...getTagProps({ index })} key={index} sx={{ mr: 1, maxWidth: "120px", fontSize: "12.5px", height: "26px", "& .MuiChip-label": { paddingLeft: "8px", paddingRight: "8px" } }} />
                ))
              }
              renderInput={(params: any) => <TextField {...params} label="Tags" error={!!errors.tags} helperText={errors.tags} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <DateRangeInput values={values} errors={errors} setStatus={setStatus} setFieldValue={setFieldValue} />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;