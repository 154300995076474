import React, { useState, MouseEvent, useEffect } from 'react';
import People from '@mui/icons-material/People';
import Business from '@mui/icons-material/Business';
import Dashboard from '@mui/icons-material/Dashboard';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { routes } from 'helper/route';
import { Box, ListSubheader, Menu } from '@mui/material';
import { perms, useAccess } from 'context/access';
import VerticalMenuItem from './VerticalMenuItem';
import { AccountCircle, Addchart, AirportShuttle, BarChart, CurrencyExchange, CardMembership, DryCleaning, EuroSymbol, LocalLaundryService, LocalShipping, LocationOn, QrCodeScanner, RequestQuote, StackedBarChart, TableRows, Warehouse, Security, AppRegistration, TrendingUp, Category, Devices, Error, CalendarMonth, PhoneAndroid, NotListedLocation, Key } from '@mui/icons-material';
import VerticalMenuHeader from './VerticalMenuHeader';

type Props = {
  isCollapsed: boolean;
};

const VerticalMenu = ({ isCollapsed }: Props) => {

  const { isGranted, isNotGranted, anyIsGranted } = useAccess();

  //SECURITY
  const [isSecuritySubmenuOpen, setIsSecuritySubmenuOpen] = useState(false);
  const [securitySubmenuAnchorElem, setSecuritySubmenuAnchorElem] = useState<(EventTarget & Element) | null>(null);
  //INFO
  const [isInfoSubmenuOpen, setIsInfoSubmenuOpen] = useState(false);
  const [infoSubmenuAnchorElem, setInfoSubmenuAnchorElem] = useState<(EventTarget & Element) | null>(null);
  //INVOICES
  const [isInvoicesSubmenuOpen, setIsInvoicesSubmenuOpen] = useState(false);
  const [invoicesSubmenuAnchorElem, setInvoicesSubmenuAnchorElem] = useState<(EventTarget & Element) | null>(null);

  const toggleSecuritySubmenu = (e: MouseEvent<HTMLDivElement>) => {
    setIsSecuritySubmenuOpen(prevState => !prevState);
    setSecuritySubmenuAnchorElem(e.currentTarget)
  }

  const toggleInfoSubmenu = (e: MouseEvent<HTMLDivElement>) => {
    setIsInfoSubmenuOpen(prevState => !prevState);
    setInfoSubmenuAnchorElem(e.currentTarget)
  }

  const toggleInvoicesSubmenu = (e: MouseEvent<HTMLDivElement>) => {
    setIsInvoicesSubmenuOpen(prevState => !prevState);
    setInvoicesSubmenuAnchorElem(e.currentTarget)
  }

  useEffect(() => {
    setIsSecuritySubmenuOpen(false);
    setIsInfoSubmenuOpen(false);
    setIsInvoicesSubmenuOpen(false);
  }, [isCollapsed])

  return <List>
    <Box className="tutorial-dashboard">
      <VerticalMenuItem icon={<Dashboard />} text="Dashboard" href={routes.home} pattern="^/(\?.*)?$" isCollapsed={isCollapsed} />
    </Box>
    {isGranted(perms.view_network) &&
      <>
        <Box className="tutorial-network">
          {!isCollapsed && <ListSubheader>Network </ListSubheader>}
          {isGranted(perms.view_users) && <VerticalMenuItem icon={<AccountCircle />} text="Users" href={routes.list_users} pattern='^/user' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_organizations) && isNotGranted(perms.view_own_organization) && <VerticalMenuItem icon={<Business />} text="Organizations" href={routes.list_organizations} pattern='^/organization' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_clients) && <VerticalMenuItem icon={<People />} text="Clients" href={routes.list_clients} pattern='^/client' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_drivers) && <VerticalMenuItem icon={<AirportShuttle />} text="Drivers" href={routes.list_drivers} pattern='^/driver' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_invoices) && <VerticalMenuItem icon={<RequestQuote />} text="Invoices" href={routes.list_invoices} pattern='^/invoice' isCollapsed={isCollapsed} />}
          {anyIsGranted([perms.view_my_invoices, perms.view_client_invoices]) &&
            <>
              <VerticalMenuHeader onClick={toggleInvoicesSubmenu} icon={<RequestQuote />} text="Invoices" isCollapsed={isCollapsed}>
                {isInvoicesSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
              </VerticalMenuHeader>
              <Collapse in={isInvoicesSubmenuOpen && !isCollapsed} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {isGranted(perms.view_my_invoices) && <VerticalMenuItem text="My Invoices" href={routes.list_my_invoice} pattern='^/my-invoice' isCollapsed={isCollapsed} isSubmenu />}
                  {isGranted(perms.view_client_invoices) && <VerticalMenuItem text="Client Invoices" href={routes.list_client_invoices} pattern='^/invoice-client' isCollapsed={isCollapsed} isSubmenu />}
                </List>
              </Collapse>
            </>
          }
          {isGranted(perms.view_intents) && <VerticalMenuItem icon={<CurrencyExchange />} text="Intents" href={routes.list_intents} pattern='^/intent' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_payment_plans) && <VerticalMenuItem icon={<CardMembership />} text="Payment Plans" href={routes.list_payment_plans} pattern='^/payment-plan' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_next_expedition) && <VerticalMenuItem icon={<LocalShipping />} text="Next dispatches" href={routes.list_next_expeditions} pattern='^/next-dispatches' isCollapsed={isCollapsed} />}
        </Box>
      </>
    }
    {isGranted(perms.view_catalog) &&
      <>
        <Box className="tutorial-catalog">
          {!isCollapsed && <ListSubheader>Catalogs </ListSubheader>}
          {isGranted(perms.view_distribution_units) && <VerticalMenuItem icon={<Warehouse />} text="Distribution Units" href={routes.list_distribution_units} pattern='^/distribution-unit' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_locations) && <VerticalMenuItem icon={<LocationOn />} text="Locations" href={routes.list_locations} pattern='^/location' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_devices) && <VerticalMenuItem icon={<PhoneAndroid />} text="Standalone Devices" href={routes.list_devices} pattern='^/device' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_articles) && <VerticalMenuItem icon={<TableRowsIcon />} text="Articles" href={routes.list_articles} pattern='^/article' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_currencies) && <VerticalMenuItem icon={<EuroSymbol />} text="Currencies" href={routes.list_currencies} pattern='^/currenc' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_exchange_rates) && <VerticalMenuItem icon={<TrendingUp />} text="Exchange Rates" href={routes.list_exchange_rates} pattern='^/exchange-rate' isCollapsed={isCollapsed} />}
        </Box>
      </>
    }
    {
      isGranted(perms.view_operation_menu) &&
      <>
        {!isCollapsed && <ListSubheader>Operations </ListSubheader>}
        {isGranted(perms.view_expeditions) && <VerticalMenuItem icon={<CalendarMonth />} text="Dispatch history" href={routes.list_expeditions} pattern='^/dispatch' isCollapsed={isCollapsed} />}
        {isGranted(perms.view_supplies) && <VerticalMenuItem icon={<Addchart />} text="Supplies" href={routes.list_supplies} pattern='^/supplies' isCollapsed={isCollapsed} />}
        {isGranted(perms.view_collection) && <VerticalMenuItem icon={<LocalLaundryService />} text="Collections" href={routes.list_collection} pattern='^/collection' isCollapsed={isCollapsed} />}
        {isGranted(perms.view_reception) && <VerticalMenuItem icon={<Category />} text="Receptions" href={routes.list_reception} pattern='^/reception' isCollapsed={isCollapsed} />}
      </>
    }
    {isGranted(perms.view_reports) &&
      <>
        <Box className="tutorial-reports">
          {!isCollapsed && <ListSubheader>Reports </ListSubheader>}
          {isGranted(perms.view_pickups) && <VerticalMenuItem icon={<DryCleaning />} text="Pickups" href={routes.list_pickups} pattern='^/pickup' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_handovers) && <VerticalMenuItem icon={<LocalLaundryService />} text="Handovers" href={routes.list_handovers} pattern='^/handover' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_supply_overview) && <VerticalMenuItem icon={<StackedBarChart />} text="DU Supply Overview" href={routes.list_supply_overview} pattern='^/overview-supply' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_article_overview) && <VerticalMenuItem icon={<BarChart />} text="Article Overview" href={routes.list_article_overview} pattern='^/overview-article' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_in_house_articles) && <VerticalMenuItem icon={<TableRows />} text="In-House Articles" href={routes.list_in_house_articles} pattern='^/in-house-articles' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_handover_corrections) && <VerticalMenuItem icon={<AppRegistration />} text="Corrections Overview" href={routes.list_handover_corrections} pattern='^/handover-corrections' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_missing_articles) && <VerticalMenuItem icon={<NotListedLocation />} text="Missing Articles" href={routes.list_missing_articles} pattern='^/missing-article' isCollapsed={isCollapsed} />}
        </Box>
      </>
    }
    {isGranted(perms.view_system) &&
      <>
        <Box className="tutorial-catalog">
          {!isCollapsed && <ListSubheader>System </ListSubheader>}
          {isGranted(perms.view_app_version) && <VerticalMenuItem icon={<Devices />} text="App version" href={routes.list_app_version} pattern='^/app-version' isCollapsed={isCollapsed} />}
        </Box>
      </>
    }
    {isGranted(perms.view_monitoring) &&
      <>
        <Box className="tutorial-catalog">
          {!isCollapsed && <ListSubheader>Monitoring </ListSubheader>}
          {isGranted(perms.view_security) &&
            <>
              <VerticalMenuHeader onClick={toggleSecuritySubmenu} icon={<Security />} text="Security" isCollapsed={isCollapsed}>
                {isSecuritySubmenuOpen ? <ExpandLess /> : <ExpandMore />}
              </VerticalMenuHeader>
              <Collapse in={isSecuritySubmenuOpen && !isCollapsed} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {isGranted(perms.view_security_overview) && <VerticalMenuItem text="Overview" href={routes.list_security_overview} pattern='^/security-overview' isCollapsed={isCollapsed} isSubmenu />}
                  {isGranted(perms.view_security_details) && <VerticalMenuItem text="Details" href={routes.list_security_details} pattern='^/security-details' isCollapsed={isCollapsed} isSubmenu />}
                  {isGranted(perms.view_offline_locations_organization) && <VerticalMenuItem text="Offline Locations" href={routes.list_offline_locations} pattern='^/offline-locations' isCollapsed={isCollapsed} isSubmenu />}
                </List>
              </Collapse>
            </>
          }
          {isGranted(perms.view_info) &&
            <>
              <Box className="tutorial-catalog">
                <VerticalMenuHeader onClick={toggleInfoSubmenu} icon={<QrCodeScanner />} text="Info" isCollapsed={isCollapsed}>
                  {isInfoSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
                </VerticalMenuHeader>
                <Collapse in={isInfoSubmenuOpen && !isCollapsed} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {isGranted(perms.view_packages) && <VerticalMenuItem text="Package" href={routes.list_packages} pattern='^/package' isCollapsed={isCollapsed} isSubmenu />}
                    {isGranted(perms.view_tags) && <VerticalMenuItem text="Tag" href={routes.list_tags} pattern='^/tag' isCollapsed={isCollapsed} isSubmenu />}
                  </List>
                </Collapse>
              </Box>
            </>
          }
          {isGranted(perms.view_activity_logs) && <VerticalMenuItem icon={<Error />} text="Activity Logs" href={routes.list_activity_logs} pattern='^/activity-log' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_offline_locations_master) && <VerticalMenuItem icon={<Security />} text="Offline Locations" href={routes.list_offline_locations} pattern='^/offline-locations' isCollapsed={isCollapsed} />}
          {isGranted(perms.view_du_access_logs) && <VerticalMenuItem icon={<Key />} text="DU Access Logs" href={routes.list_du_access_logs} pattern='^/du-access-log' isCollapsed={isCollapsed} />}
        </Box>
      </>
    }
    {/* SECURITY */}
    <Menu
      anchorEl={securitySubmenuAnchorElem}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isSecuritySubmenuOpen && isCollapsed}
      onClose={toggleSecuritySubmenu}
    >
      <List component="div" disablePadding>
        <ListItem component={Link} href={routes.list_security_overview}>
          <ListItemText primary="Security Overview" />
        </ListItem>
        <ListItem component={Link} href={routes.list_security_details}>
          <ListItemText primary="Security Details" />
        </ListItem>
      </List>
    </Menu>
    {/* INFO */}
    <Menu
      anchorEl={infoSubmenuAnchorElem}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isInfoSubmenuOpen && isCollapsed}
      onClose={toggleInfoSubmenu}
    >
      <List component="div" disablePadding>
        <ListItem component={Link} href={routes.list_packages}>
          <ListItemText primary="Package info" />
        </ListItem>
        <ListItem component={Link} href={routes.list_tags}>
          <ListItemText primary="Tag info" />
        </ListItem>
      </List>
    </Menu>
    {/* INVOICES */}
    <Menu
      anchorEl={invoicesSubmenuAnchorElem}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isInvoicesSubmenuOpen && isCollapsed}
      onClose={toggleInvoicesSubmenu}
    >
      <List component="div" disablePadding>
        <ListItem component={Link} href={routes.list_my_invoice}>
          <ListItemText primary="My Invoices" />
        </ListItem>
        <ListItem component={Link} href={routes.list_client_invoices}>
          <ListItemText primary="Client Invoices" />
        </ListItem>
      </List>
    </Menu>
  </List>
}

export default VerticalMenu;