import { Check } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Link, Typography } from "@mui/material";
import Client from "model/client";
import React from "react";

type Props = {
  payload: any,
  setAgreeTerms: Function,
  agreeTerms: boolean,
  setConfirmedTerms: Function,
  setAgreePenalty?: Function,
  agreePenalty?: boolean
}

const TermsAndConditions = ({ payload, setAgreeTerms, agreeTerms, setConfirmedTerms, setAgreePenalty, agreePenalty }: Props) => {
  return (
    <Box sx={{ width: { md: 450 } }}>
      <Typography variant='h5' sx={{ textAlign: "center" }}>Welcome {payload.firstName} {payload.lastName}!</Typography>
      <Typography variant='body1' sx={{ textAlign: "center", marginTop: "50px" }}>To begin your registration process, please read and agree to the terms and conditions of service.</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2, mb: 2 }}>
        <Box>
          <FormControlLabel control={<Checkbox checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} />} label={<Box sx={{ fontSize: "15px" }}>I agree to the <Link href={payload.accountType === "organization" ? `${process.env.REACT_APP_API_URL}/static/terms/OrganizationTerms.pdf` : `${process.env.REACT_APP_API_URL}/static/terms/ClientTerms.pdf`} target="_blank">Terms & Conditions</Link></Box>} />
        </Box>
        {
          (payload.accountType !== "organization" && payload.businessModel !== Client.BUSINESS_MODEL_NON_SSD && payload.businessModel !== Client.BUSINESS_MODEL_FREE_SSD) &&
          <Box sx={{ mt: 1 }}>
            <FormControlLabel control={<Checkbox checked={agreePenalty} onChange={(e) => setAgreePenalty && setAgreePenalty(e.target.checked)} />} label={<Box sx={{ fontSize: "15px" }}>I hereby acknowledge that overdue handovers will be charged a daily penalty of {payload.organizationPenaltyPercent}% of the value of each overdue article</Box>} />
          </Box>
        }
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant='contained' size='large' disabled={payload.businessModel === Client.BUSINESS_MODEL_SSD ? !agreeTerms || !agreePenalty : !agreeTerms} startIcon={<Check />} onClick={() => setConfirmedTerms(true)}>Continue</Button>
      </Box>
    </Box >
  )
}

export default TermsAndConditions;