import Client from "./client";
import Organization from "./organization";

type SubjectT = {
  userId: number;
  userName: string;
  entityType: number;
}

export default class AccessLog {
  id!: number;
  organization!: Organization;
  distributionUnit!: any;
  client!: Client;
  tags!: number[];
  subject!: SubjectT;
  occurences!: number;
  device!: any;
  updatedTs!: number;
  createdTs!: number;

  /********** TAG ***********/

  static TAG_MASTER = 102;
  static TAG_ORG = 103;
  static TAG_CLIENT = 104;

  /********** ENTITIES **********/

  static ENTITY_TYPE_MASTER = 1;
  static ENTITY_TYPE_ORG = 2;
  static ENTITY_TYPE_CLIENT = 3;

  static TagTypes = [
    {
      id: this.TAG_MASTER,
      name: "Master"
    },
    {
      id: this.TAG_ORG,
      name: "Organization"
    },
    {
      id: this.TAG_CLIENT,
      name: "Client"
    },
  ];

  static EntityTypes = [
    {
      id: this.ENTITY_TYPE_MASTER,
      name: "Master"
    },
    {
      id: this.ENTITY_TYPE_ORG,
      name: "Organization"
    },
    {
      id: this.ENTITY_TYPE_CLIENT,
      name: "Client"
    }
  ];

  static getTagTypeName(id: number) {
    return this.TagTypes.find((tag) => tag.id === id)?.name
  }

  static getEntityTypeName(id: number) {
    return this.EntityTypes.find((type) => type.id === id)?.name
  }
}