import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft } from "@mui/icons-material";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import Preloader from 'component/common/Preloader';
import ConfirmDialog from "component/common/ConfirmDialog";
import { perms, useAccess } from "context/access";
import { deleteAccessLog, getAccessLog } from "helper/backend";
import { routes } from "helper/route";
import { showError, showSuccess, toSimpleError } from "helper/util";
import { useNavigate, useParams } from "react-router-dom";
import { AppError } from "@type";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import AccessLog from "model/accessLog";
import { formats, formatTimestamp } from "helper/date";
import SectionInfo from "./Partial/SectionInfo";
import SectionDu from "./Partial/SectionDu";
import SectionUser from "./Partial/SectionUser";
import SectionDevice from "./Partial/SectionDevice";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the access log record fetched from the backend
  const [accessLog, setAccessLog] = useState<AccessLog | undefined>()
  // error encoutered while fetching the access log (if any)
  const [accessLogError, setAccessLogError] = useState<AppError | undefined>();
  // whether the loading of the access log is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the access log is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /**
  * Fetches the access log record from the backend
  */
  const refreshAccessLog = useCallback(() => {
    setIsLoadInProgress(true)
    getAccessLog(recordId)
      .then(response => {
        setAccessLog(response.accessLog);
      })
      .catch(ex => {
        setAccessLogError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId])

  /**
   * Deletes the access log record in the backend
   */
  const removeAccessLog = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteAccessLog(recordId)
      .then(_response => {
        showSuccess(t("accessLogHasBeenDeleted"));
        return navigate(routes.list_du_access_logs);
      })
      .catch(_ex => {
        showError(t("unableToDeleteAccessLog"));
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshAccessLog();
  }, [refreshAccessLog]);

  return (
    <>
      {isGranted(perms.view_du_access_logs)
        &&
        <>
          {
            !!accessLog
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(accessLog, t)}>
                {isGranted(perms.delete_access_logs) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteAccessLog")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={routes.list_du_access_logs} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo accessLog={accessLog} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionDu accessLog={accessLog} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionUser accessLog={accessLog} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionDevice accessLog={accessLog} />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton="Delete"
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeAccessLog();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteAccessLogConfirmation")}</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !accessLog && <Preloader container="content" />}
          {!!accessLogError && <Error error={accessLogError} title404={t("accessLogNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_du_access_logs) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (accessLog: AccessLog, t: Function) => [{
  text: t("duAccessLogs"),
  url: routes.list_du_access_logs,
}, {
  text: formatTimestamp(accessLog.createdTs, formats.LONGDATETIME),
}];

export default Single;