import { combineReducers } from 'redux';

import Layout from './layout/reducer';
import Auth from './auth/reducer';
import User from './user/reducer';
import Organization from './organization/reducer';
import Article from './article/reducer';
import Client from './client/reducer';
import DistributionUnit from './distributionUnit/reducer';
import Location from './location/reducer';
import Device from './device/reducer';
import Pickup from './pickup/reducer';
import Handover from './handover/reducer';
import SupplyOverview from './supplyOverview/reducer';
import ArticleOverview from './articleOverview/reducer';
import Package from './package/reducer';
import Tag from './tag/reducer';
import Currency from './currency/reducer';
import Driver from './driver/reducer';
import Expedition from './expedition/reducer';
import Supplies from './supplies/reducer';
import ArticleInHouse from './articleInHouse/reducer';
import Invoice from './invoice/reducer';
import MyInvoice from './myInvoices/reducer';
import ClientInvoice from './clientInvoices/reducer';
import Intent from './intent/reducer';
import PaymentPlan from './paymentPlan/reducer';
import SecurityOverview from './securityOverview/reducer';
import SecurityDetails from './securityDetails/reducer';
import Collection from './collection/reducer';
import HandoverCorrections from './handoverCorrections/reducer';
import ExchangeRate from './exchangeRate/reducer';
import Reception from './reception/reducer';
import AppVersion from './appVersion/reducer';
import ActivityLog from './activityLog/reducer';
import OfflineLocations from './offlineLocations/reducer';
import MissingArticle from './missingArticle/reducer';
import AccessLog from './accessLog/reducer';

export default combineReducers({
  Layout,
  Auth,
  User,
  Organization,
  Article,
  Client,
  DistributionUnit,
  Location,
  Device,
  Pickup,
  Handover,
  Package,
  Tag,
  Currency,
  SupplyOverview,
  ArticleOverview,
  Driver,
  Expedition,
  Supplies,
  ArticleInHouse,
  Invoice,
  MyInvoice,
  ClientInvoice,
  Intent,
  PaymentPlan,
  SecurityOverview,
  SecurityDetails,
  Collection,
  HandoverCorrections,
  ExchangeRate,
  Reception,
  AppVersion,
  ActivityLog,
  OfflineLocations,
  MissingArticle,
  AccessLog
});
