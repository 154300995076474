import {
  APPLY_ACCESS_LOGS_DT_PARAMS,
  PATCH_ACCESS_LOGS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyAccessLogDtParams = (params: DtParams) => ({
  type: APPLY_ACCESS_LOGS_DT_PARAMS,
  payload: { params },
});

export const patchAccessLogDtParams = (params: DtParams) => ({
  type: PATCH_ACCESS_LOGS_DT_PARAMS,
  payload: { params },
});