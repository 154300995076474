import React from "react";
import { CardContent, Chip, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import EditableCard from "component/styled/EditableCard";
import { formatTimestamp, formats } from "helper/date";
import AccessLog from "model/accessLog";

type Props = {
  accessLog: AccessLog;
};

const SectionInfo = ({ accessLog }: Props) => {

  const { t } = useTranslation();

  return <React.Fragment>
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("generalInfo")} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell>{t("occurences")}</TableCell>
              <TableCell className="preview-value">{accessLog.occurences}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("tagsLabel")}</TableCell>
              <TableCell className="preview-value">{accessLog.tags.map((tag: any, idx: number) => {
                return <Chip label={AccessLog.getTagTypeName(tag)} key={idx} sx={{ mr: 1, maxWidth: "120px", fontSize: "12.5px", height: "26px", "& .MuiChip-label": { paddingLeft: "8px", paddingRight: "8px" } }} />
              })}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("date")}</TableCell>
              <TableCell className="preview-value">{formatTimestamp(accessLog.createdTs, formats.LONGDATETIME)}</TableCell>
            </TableRow>
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  </React.Fragment >

};

export default SectionInfo;