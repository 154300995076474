import React from "react";
import { CardContent, Link, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import EditableCard from "component/styled/EditableCard";
import Device from "model/device";
import { routes, route } from "helper/route";
import AccessLog from "model/accessLog";

type Props = {
  accessLog: AccessLog;
};

const SectionDevice = ({ accessLog }: Props) => {

  const { t } = useTranslation();

  return <React.Fragment>
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("device")} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell>{t("device")}</TableCell>
              <TableCell className="preview-value">
                <Link href={route(routes.view_du_device, [accessLog.distributionUnit.distributionUnitId, accessLog.device.deviceId])}>{accessLog.device.deviceName}</Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("deviceType")}</TableCell>
              <TableCell className="preview-value">{Device.getDeviceTypeName(accessLog.device.deviceType)}</TableCell>
            </TableRow>
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  </React.Fragment >

};

export default SectionDevice;