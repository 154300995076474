import { get, post, put, del } from './api';
import {
  ArticleUsageType,
  AuthRequest,
  AuthResponse,
  BusinessModelType,
  ClientCorrectionsType,
  DuePickupType,
  ForgotPassRequest,
  GeoDistributionType,
  GetAccessRolesResponse,
  GetActivityLogResponse,
  GetAppVersionResponse,
  GetArticleResponse,
  GetAuthUserResponse,
  GetBillingResponse,
  GetClientResponse,
  GetClientsResponse,
  GetCurrenciesResponse,
  GetCurrencyResponse,
  GetDeviceResponse,
  GetDevicesResponse,
  GetDriversResponse,
  GetDuArticleResponse,
  GetDuArticlesResponse,
  GetDuResponse,
  GetDuStockLevel,
  GetEntityDtResponse,
  GetExchangeRateResponse,
  GetExpeditionLocationsResponse,
  GetIntentResponse,
  GetInvoiceResponse,
  GetLocationResponse,
  GetLocationsListResponse,
  GetMasterSettingsResponse,
  GetMyPaymentPlanResponse,
  GetOperationItemsResponse,
  GetOperationResponse,
  GetOrgDusResponse,
  GetOrganizationBillingResponse,
  GetOrganizationResponse,
  GetOrganizationsResponse,
  GetPackageResponse,
  GetPaymentPlanResponse,
  GetPublicKey,
  GetQuotaResponse,
  GetReaderResponse,
  GetReadersResponse,
  GetTagResponse,
  GetUserResponse,
  GetVerificationLinkResponse,
  Handover,
  HighestRevenueType,
  InHouseArticlesType,
  NextExpeditionType,
  LogoutResponse,
  RevenueType,
  SaveAccountRequest,
  SaveArticleRequest,
  SaveClientRequest,
  SaveCurrencyRequest,
  SaveDistributionUnitRequest,
  SaveDuArticleRequest,
  SaveDuDeviceRequest,
  SaveExchangeRate,
  SaveLocationRequest,
  SaveOrganizationRequest,
  SavePaymentPlanRequest,
  SaveReaderRequest,
  SaveStandaloneDeviceRequest,
  SaveUserRequest,
  SetPasswordRequest,
  GetNextExpeditionListResponse,
  ConfirmExpeditionRequest,
  GetSelfInviteClientToken,
  GetOrganizationValidUuid,
  GetAccessLogResponse,
} from '@type/backend';
import { DtParams } from '@type';
import { flattenFilters } from './dt';
import User from 'model/user';
import Organization from 'model/organization';
import Article from 'model/article';
import Client from 'model/client';
import DistributionUnit from 'model/distributionUnit';
import Location from 'model/location';
import Device from 'model/device';
import Operation from 'model/operation';
import Overview from 'model/overview';
import Currency from 'model/currency';
import Invoice from 'model/invoice';
import Intent from 'model/intent';
import PaymentPlan from 'model/paymentPlan';
import Tag from 'model/tag';
import ExchangeRate from 'model/exchangeRate';
import { ArticlesReport } from 'component/dashboard/Articles';
import AppVersion from 'model/appVersion';
import ActivityLog from 'model/activityLog';
import { OfflineLocation } from 'page/OfflineLocations/List';
import AccessLog from 'model/accessLog';

const getEntityDt = <Type>(url: string, params: DtParams) => get<Type>(url, { params: flattenFilters(params) });

// Auth

export const getAuthUser = () => get<GetAuthUserResponse>('/me');

export const loginUser = (data: AuthRequest) => post<AuthResponse>('/login', data);

export const logoutUser = () => post<LogoutResponse>('/logout');

export const impersonateUser = (id: number) => post<AuthResponse>(`/impersonate/${id}`);

export const updateAccount = (data: SaveAccountRequest) => put('/me', data)

export const updatePassword = (data: SetPasswordRequest) => put('/me/password', data);

export const changeAccessCode = () => put('/me/access-code/change');

export const getMasterCode = () => get('/master/code');

// User

export const getUserDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<User>>('/users/dt', params);

export const getUser = (id: number) => get<GetUserResponse>(`/user/${id}`);

export const createUser = (data: SaveUserRequest) => post('/user', data);

export const updateUser = (id: number, data: SaveUserRequest) => put(`/user/${id}`, data);

export const deleteUser = (id: number) => del(`/user/${id}`);

export const forgotPass = (email: ForgotPassRequest) => post('/user/request-password-reset', email);

export const preResetPassword = (token: string) => post<any>(`/user/pre-reset-password/${token}`);

export const resetPassword = (token: string, data: SetPasswordRequest) => put(`/user/reset-password/${token}`, data);

export const sendVerificationLink = (userId: number) => post(`/user/${userId}/send-verification-link`);

export const getVerificationLink = (userId: number) => get<GetVerificationLinkResponse>(`/user/${userId}/verification-link`);

export const preActivateAccount = (token: string) => post<any>(`/user/pre-activate-account/${token}`);

export const activateAccount = (token: string, data: SetPasswordRequest) => put(`/user/activate-account/${token}`, data);

export const verifyEmail = (token: string) => put(`/user/verify-email/${token}`);

export const tutorialComplete = () => put('/me/tutorial-complete')

// Driver

export const getDriverDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<User>>('/drivers/dt', params);

export const createDriver = (data: any) => post('/driver', data);

export const updateDriver = (id: number, data: SaveUserRequest) => put(`/driver/${id}`, data);

export const getDriversList = () => get<GetDriversResponse>('/organization/my-drivers')

// Organizations

export const getOrganizationDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Organization>>('/organizations/dt', params);

export const getOrganization = (id: number) => get<GetOrganizationResponse>(`/organization/${id}`);

export const getOwnOrganization = () => get<GetOrganizationResponse>('/me/organization');

export const updateOwnOrganization = (data: any) => put(`me/organization`, data);

export const createOrganization = (data: SaveOrganizationRequest) => post('/organization', data);

export const updateOrganization = (id: number, data: SaveOrganizationRequest) => put(`/organization/${id}`, data);

export const deleteOrganization = (id: number) => del(`/organization/${id}`);

export const getOrganizationList = () => get<GetOrganizationsResponse>('/organizations');

export const getOrganizationAdmins = (id: number) => get<any>(`/organization/${id}/admins`);

export const preRegisterOrganization = (token: string) => post<any>(`/organization/pre-register/${token}`);

export const registerOrganization = (token: string, data: SaveOrganizationRequest) => post(`/organization/register/${token}`, data);

export const inviteOrganization = (data: SaveOrganizationRequest) => post('/organization/invite', data);

export const getOrganizationBilling = () => get<GetOrganizationBillingResponse>('/me/organization/billing');

export const checkOrganizationValidUuid = (uuid: string) => get<GetOrganizationValidUuid>(`/organization/valid/${uuid}`);

//Articles

export const getArticleDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Article>>('/articles/dt', params);

export const getArticle = (id: number) => get<GetArticleResponse>(`/article/${id}`);

export const updateArticle = (id: number, data: SaveArticleRequest) => put(`/article/${id}`, data);

export const deleteArticle = (id: number) => del(`/article/${id}`);

export const createArticle = (data: any) => post('/article', data);

export const getArticleInHouseDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Overview>>('/articles/in-house', params);

// Clients

export const getClientList = () => get<GetClientsResponse>('/clients');

export const getClientDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Client>>('/clients/dt', params);

export const getClient = (id: number) => get<GetClientResponse>(`/client/${id}`);

export const createClient = (data: SaveClientRequest) => post('/client', data);

export const deleteClient = (id: number) => del(`/client/${id}`);

export const updateClient = (id: number, data: SaveClientRequest) => put(`/client/${id}`, data);

export const getClientAdmins = (id: number) => get<any>(`/client/${id}/admins`);

export const getOwnClient = () => get<GetClientResponse>('/me/client');

export const updateOwnClient = (data: SaveClientRequest) => put(`me/client`, data);

export const inviteClient = (data: SaveClientRequest) => post('/client/invite', data);

export const preRegisterClient = (token: string) => post<any>(`/client/pre-register/${token}`);

export const registerClient = (token: string, data: SaveClientRequest) => post(`/client/register/${token}`, data);

export const selfInviteClient = (data: SaveClientRequest, uuid: string) => post<GetSelfInviteClientToken>(`/client/self-invite/${uuid}`, data);

// Distribution Units

export const getDuDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<DistributionUnit>>('/distribution-units/dt', params);

export const getAllDuDt = (params: DtParams | undefined) => getEntityDt<GetEntityDtResponse<DistributionUnit>>('/distribution-units/dt', params || {});

export const createDu = (data: any) => post('/distribution-unit', data);

export const getDu = (id: number) => get<GetDuResponse>(`/distribution-unit/${id}`);

export const updateDu = (id: number, data: SaveDistributionUnitRequest) => put(`/distribution-unit/${id}`, data);

export const deleteDu = (id: number) => del(`/distribution-unit/${id}`);

export const getDuDevices = (id: number) => get<GetDevicesResponse>(`/distribution-unit/${id}/devices`);

export const getDuArticles = (id: number) => get<GetDuArticlesResponse>(`/distribution-unit/${id}/articles`);

export const createDuArticle = (data: any) => post('/distribution-unit-article', data);

export const getUnusedDuArticles = (id: number) => get<GetDuArticlesResponse>(`/distribution-unit/${id}/unused-articles`);

export const getDuArticle = (id: number) => get<GetDuArticleResponse>(`/distribution-unit-article/${id}`);

export const deleteDuArticle = (id: number) => del(`/distribution-unit-article/${id}`);

export const updateDuArticle = (id: number, data: SaveDuArticleRequest) => put(`/distribution-unit-article/${id}`, data);

export const getDuSupplyPDF = (id: number) => get<any>(`/distribution-unit/${id}/supply/pdf`, { responseType: 'blob' });

// Locations

export const getLocationDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Location>>('/locations/dt', params);

export const getLocation = (id: number) => get<GetLocationResponse>(`/location/${id}`);

export const createLocation = (data: any) => post('/location', data);

export const updateLocation = (id: number, data: SaveLocationRequest) => put(`/location/${id}`, data);

export const deleteLocation = (id: number) => del(`/location/${id}`);

export const getLocationListByOrganization = (id: number) => get<GetLocationsListResponse>(`/organization/${id}/locations-standalone`);

export const getExpeditionLocationsList = () => get<GetExpeditionLocationsResponse>('/expedition-locations');

export const getOrganizationLocationsList = () => get<GetExpeditionLocationsResponse>(`/locations`);

export const getOrganizationDistributionUnits = () => get<GetOrgDusResponse>('/distribution-units')

// Devices

export const getDeviceDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Device>>('/devices/dt', params);

export const createDuDevice = (id: number, data: SaveDuDeviceRequest) => post(`/distribution-unit/${id}/device`, data);

export const createStandaloneDevice = (data: SaveDuDeviceRequest) => post(`/device`, data);

export const getDevice = (id: number) => get<GetDeviceResponse>(`/device/${id}`);

export const updateDuDevice = (deviceId: number, duId: number, data: SaveDuDeviceRequest) => put(`distribution-unit/${duId}/device/${deviceId}`, data);

export const updateDevice = (id: number, data: SaveStandaloneDeviceRequest) => put(`device/${id}`, data);

export const deleteDevice = (id: number) => del(`/device/${id}`);

export const getReaders = (id: number) => get<GetReadersResponse>(`/device/${id}/readers`);

export const getReader = (id: number) => get<GetReaderResponse>(`/reader/${id}`);

export const createReader = (id: number, data: any) => post(`/device/${id}/reader`, data);

export const deleteReader = (id: number) => del(`/reader/${id}`);

export const updateReader = (id: number, data: SaveReaderRequest) => put(`/reader/${id}`, data);

// Pickups

export const getPickupsDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Operation>>('/pickups/dt', params);

// Handovers

export const getHandoversDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Operation>>('/handovers/dt', params);

// Expeditions

export const getExpeditionDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Operation>>('/expeditions/dt', params);

export const getNextExpeditionList = () => get<GetNextExpeditionListResponse>('/expeditions/next/list');

export const confirmExpedition = (data: ConfirmExpeditionRequest) => post('/expedition/confirm', data);

// Supplies

export const getSuppliesDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Operation>>('/supplies/dt', params);

// Collection

export const getCollectionDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Operation>>('/collections/dt', params);

// Reception

export const getReceptionDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Operation>>('/receptions/dt', params);

// Overview

export const getSupplyOverviewDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Overview>>('/stock/overview', params);

export const getStockOverviewDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Overview>>('/articles/stock', params);

export const getArticleOverviewCsv = () => get<any>(`/overview/csv`, { responseType: 'blob' });

// Handover Corrections

export const getHandoverCorrectionsDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Overview>>('/handover-corrections/dt', params);

// Operations

export const getOperation = (id: number) => get<GetOperationResponse>(`/operation/${id}`);

export const getOperationPDF = (id: number) => get<any>(`/operation/${id}/pdf`, { responseType: 'blob' });

export const getOperationItems = (id: number) => get<GetOperationItemsResponse>(`/operation/${id}/items`);

// Packages

export const getPackages = (params: DtParams) => getEntityDt<GetPackageResponse>('/package', params);

// Tags

export const getTags = (params: DtParams) => getEntityDt<GetTagResponse>('/tag', params);

// Currency

export const getCurrenciesDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Currency>>('/currencies/dt', params);

export const createCurrency = (data: SaveCurrencyRequest) => post('/currency', data);

export const getCurrency = (id: number) => get<GetCurrencyResponse>(`/currency/${id}`);

export const deleteCurrency = (id: number) => del(`/currency/${id}`);

export const updateCurrency = (id: number, data: SaveCurrencyRequest) => put(`/currency/${id}`, data);

export const getCurrenciesList = () => get<GetCurrenciesResponse>('/currencies');

// Billing Info

export const getOwnBilling = () => get<GetBillingResponse>('/me/billing');

export const updateOwnBilling = (data: any) => put('/me/billing', data);

// Access Role

export const getAccessRoleList = () => get<GetAccessRolesResponse>('/access-roles');

// Payment

export const createPaymentCustomer = () => post('/payment/create-customer');

export const createPaymentSetupIntent = () => post('/payment/create-setup-intent');

export const updatePayment = (data: any) => put('/payment/update-payment-method', data);

export const getPublicKey = () => get<GetPublicKey>('/payment/public-key')

// Dashboard

export const getDuStockLevel = () => get<GetDuStockLevel>('/distribution-units/supply');

export const getOverDuePickup = () => get<DuePickupType>('/organization/overdue-pickups');

export const getNextHandover = () => get<Handover>('/handovers/next');

export const getUpcomingHandovers = () => get<DuePickupType>('/handovers/upcoming');

export const getOverdueHandovers = () => get<DuePickupType>('/me/overdue-pickups');

export const getBusinessModels = () => get<BusinessModelType>('/business-models');

export const getRevenue = () => get<RevenueType>('/revenue');

export const getClientCorrections = () => get<ClientCorrectionsType>('/client-corrections');

export const getArticlesReport = () => get<ArticlesReport>('/articles-report');

export const getHighestRevenue = () => get<HighestRevenueType>('/last-month/revenue');

export const getGeographicDistribution = () => get<GeoDistributionType>('/country/organizations');

export const getArticleUsage = () => get<ArticleUsageType>('/article/usage');

export const getInHouseArticles = () => get<InHouseArticlesType>('/articles/in-house/count');

export const getNextExpedition = () => get<NextExpeditionType>('/expeditions/next');

// Master Settings

export const getMasterSettings = () => get<GetMasterSettingsResponse>('/master/settings');

export const updateMasterSettings = (data: any) => put('/master/settings', data);

// Invoices

export const getInvoiceDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Invoice>>('/invoices/dt', params);

export const getClientsInvoiceDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Invoice>>('/invoices/clients/dt', params);

export const getInvoice = (id: number) => get<GetInvoiceResponse>(`/invoice/${id}`);

export const getInvoicePDF = (id: number) => get<any>(`/invoice/${id}/pdf`, { responseType: 'blob' });

// Intents

export const getIntentDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Intent>>('/intents/dt', params);

export const getIntent = (id: number) => get<GetIntentResponse>(`/intent/${id}`);

export const deleteIntent = (id: number) => del(`/intent/${id}`);


// Payment Plans

export const getPaymentPlansDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<PaymentPlan>>('/payment-plans/dt', params);

export const createPaymentPlan = (data: SavePaymentPlanRequest) => post('/payment-plan', data);

export const getPaymentPlan = (id: number) => get<GetPaymentPlanResponse>(`/payment-plan/${id}`);

export const deletePaymentPlan = (id: number) => del(`/payment-plan/${id}`);

export const updatePaymentPlan = (id: number, data: SavePaymentPlanRequest) => put(`/payment-plan/${id}`, data);

export const getMyPaymentPlans = () => get<GetMyPaymentPlanResponse>('/me/payment-plans')

export const assignPaymentPlan = (id: number) => post(`/payment-plan/${id}/assign`);

export const assignPaymentPlanOnboarding = (id: number) => post(`/payment/assign-payment-plan/${id}`);

export const getOrganizationPaymentPlans = () => get<GetMyPaymentPlanResponse>(`/payment-plans`);

export const getPaymentPlanQuota = () => get<GetQuotaResponse>(`/payment-plan/quota`);

// Security

export const getSecurityOverviewDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Article>>('/security/overview/dt', params);

export const getSecurityDetailsDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Tag>>('/security/details/dt', params);

export const getOfflineLocationsDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<OfflineLocation>>('/security/offline-locations/dt', params);

// Exchange rates

export const getExchangeRatesDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<ExchangeRate>>('/exchange-rates/dt', params);

export const createExchangeRate = (data: SaveExchangeRate) => post('/exchange-rate', data);

export const updateExchangeRate = (data: SaveExchangeRate, dateTs: number) => put(`/exchange-rate/${dateTs}`, data)

export const getExchangeRate = (dateTs: number) => get<GetExchangeRateResponse>(`/exchange-rate/${dateTs}`);

export const deleteExchangeRate = (dateTs: number) => del(`/exchange-rate/${dateTs}`);

export const pullExchangeRate = (dateTs: number) => post(`/exchange-rate/${dateTs}/pull`);

// App Version

export const getAppVersionDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<AppVersion>>('/app-versions/dt', params);

export const createAppVersion = (data: any) => post('/app-version', data);

export const getAppVersion = (id: number) => get<GetAppVersionResponse>(`/app-version/${id}`);

export const deleteAppVersion = (id: number) => del(`/app-version/${id}`);

export const getAppFile = (id: number) => get<any>(`/app-version/${id}/download`, { responseType: 'blob' });

export const promoteAppToStable = (id: number) => put(`/app-version/${id}/stable`);

export const activateAppVersion = (id: number) => put(`/app-version/${id}/toggle-active`);

export const getAppVersionDevices = (id: number) => get<GetDevicesResponse>(`/app-version/${id}/devices`);

// Activity Logs

export const getActivityLogDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<ActivityLog>>('/activity-logs/dt', params);

export const getActivityLog = (id: number) => get<GetActivityLogResponse>(`/activity-log/${id}`);

export const deleteActivityLog = (id: number) => del(`/activity-log/${id}`);

// Missing Articles

export const getMissingArticlesDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<Operation>>('/missing-articles/dt', params);

// DU Access Logs

export const getDuAccessLogDt = (params: DtParams) => getEntityDt<GetEntityDtResponse<AccessLog>>('/access-logs/dt', params);

export const getAccessLog = (_seq: number) => get<GetAccessLogResponse>(`/access-log/${_seq}`);

export const deleteAccessLog = (_seq: number) => del(`/access-log/${_seq}`);