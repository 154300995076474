import React from "react";
import { CardContent, Link, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import EditableCard from "component/styled/EditableCard";
import AccessLog from "model/accessLog";
import { route, routes } from "helper/route";
import { useAuth } from "context/auth";

type Props = {
  accessLog: AccessLog;
};

const SectionDu = ({ accessLog }: Props) => {

  const { t } = useTranslation();
  const { authUser } = useAuth();

  return <React.Fragment>
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("distributionUnit")} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell className="preview-value">
                <Link href={route(routes.view_distirbution_unit, accessLog.distributionUnit.distributionUnitId)}>{accessLog.distributionUnit.distributionUnitName}</Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("location")}</TableCell>
              <TableCell className="preview-value">
                {
                  authUser.isOrganizationAdmin()
                    ?
                    <Link href={route(routes.view_location, accessLog.device.locationId)}>{accessLog.device.locationName}</Link>
                    :
                    accessLog.device.locationName
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  </React.Fragment >

};

export default SectionDu;