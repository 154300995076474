import 'moment-timezone';
import moment from 'moment';

export const formats = {
  DATE: 'MMM D',
  LONG_DATE: 'MMM D, Y',
  DATETIME: 'MMM D, H:mm',
  LONGDATETIME: 'MMM D, Y, H:mm',
  DATE_SPLIT: 'DD-MM-YYYY',
  TIME: 'HH:mm',
  LONG_TIME: 'HH-mm-ss'
}

export const initTimezone = (zone: string) => moment.tz.setDefault(zone);

export const formatDate = (date: Date, format: string) => moment(date).format(format);

export const formatTimestamp = (timestamp: number, format: string) => moment.unix(timestamp).format(format);

export const timestamp = (date?: Date) => {
  const milliseconds = date ? date.getTime() : Date.now();
  return Math.floor(milliseconds / 1000);
}

export const dateFromTimestamp = (timestamp: number) => new Date(timestamp * 1000);

export const getTimezoneOptions = () => moment.tz.names().map(zone => ({
  label: `${zone} (${moment.tz(zone).zoneAbbr()})`,
  value: zone,
}));

export const getTimePassed = (timePassed: number) => {
  if (timePassed < 60) {
    //seconds
    return `${Math.floor(timePassed)}sec`
  } else if (timePassed < 3600) {
    //minutes
    return `${Math.floor(timePassed / 60)}min`
  } else if (timePassed < 86400) {
    //hours
    return `${Math.floor(timePassed / 3600)}h`
  } else if (timePassed < 2592000) {
    //days
    return `${Math.floor(timePassed / 86400)}d`
  } else {
    //months
    return `${Math.floor(timePassed / 2592000)}mo`
  }
}